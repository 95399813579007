// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-test-detail-js": () => import("./../src/templates/test-detail.js" /* webpackChunkName: "component---src-templates-test-detail-js" */),
  "component---src-templates-link-check-js": () => import("./../src/templates/link-check.js" /* webpackChunkName: "component---src-templates-link-check-js" */),
  "component---src-pages-catalogus-az-js": () => import("./../src/pages/catalogusAZ.js" /* webpackChunkName: "component---src-pages-catalogus-az-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */)
}

